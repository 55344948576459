import React from "react"
import { graphql, Link } from "gatsby"

import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"

const Country = ({ data, location }) => {
    const allUsStates = data.allUsStates?.nodes?.filter(
        (node, index, self) =>
            index === self.findIndex((t) => t.path === node.path)
    )

    return (
        <>
             <MetaData 
                location={location} 
                title="Popular Motorsport States within United States"
                description="Find motorsport and go-karting tracks in your state."
                />
            <Layout>
                <h1>Race Tracks by States</h1>
                <ul className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {allUsStates.map(({ context, path }) => (
                        <li>
                            <a href={path}>{context.state}</a>
                        </li>
                    ))}
                </ul>
            </Layout>
        </>
    )
}
export default Country

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
// countryPath: gatsbyPath(filePath: "/countries/{worldcitiesFilteredCsv.country}")
export const pageQuery = graphql`
    query StatesQuery {
        allUsStates: allSitePage(filter: { path: { regex: "/^/states//" } }) {
            nodes {
                path
                context {
                    state
                }
            }
        }
    }
`
